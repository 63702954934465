import React from 'react'
import '../style.css'

const Header = () => {
  return (
  <>
    <section id='header'>
        <div className="hero">
          <div className="container blue">
            <div className="row">
              <div className="col-lg-7">
                <h1>Dapatkan Beasiswa Hingga Rp.100jt untuk Kuliah Kesehatan di Kanada</h1>
                <h5><b>100% Langsung Kerja : Gaji hingga Rp.45 juta/ bulan</b></h5>
                <a href="#register" className='btn btn-danger mt-3 p-3' style={{ textDecoration:'none', color:'white' }}>Daftar Sekarang Juga</a>
              </div>
            </div>
          </div>
        </div>
    </section>
  </>
  )
}

export default Header