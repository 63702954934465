import React from 'react'
import { Parallax, Background } from 'react-parallax';
import '../style.css'
const Consultation = () => {
  return (
    <Parallax strength={200} bgImage=''>
        <section id="consultation">
            <div className="container">
                <div className="row content1">
                    <div className="col-lg-12">
                        <h3>Silahkan hubungi kami untuk informasi lebih detail</h3>
                        <a className='btn btn-danger' href="https://wa.me/6285175307090" target='blank' style={{ textDecoration:'none', color:'white' }}>Konsultasi Sekarang</a>
                    </div>
                    {/* <div className="col-lg-4 d-flex align-items-center justify-content-center">
                        
                    </div> */}
                </div>
            </div>
        </section>
    </Parallax>
  )
}

export default Consultation