import '../style.css'
import Paud from '../img/early.jpg';
import Nursing from '../img/healthcare.jpg'
const WhySection = () => {
  return (
    <section id="program-section">
  <div className="container">
    <h3 className="program-title">Tentang Jurusan</h3>
    <div className="row center">
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="card shadow">
          <img src={Paud} alt="Early Childhood Education" className="custom-img" />
          <div className="card-body">
            <h5 className="card-title">
                <b>
                    <a href="https://international.stenbergcollege.com/program/early-childhood-education/" target='blank' className='custom-link'>Early Childhood Educators Diploma</a>
                </b>
                </h5>
            <p>Kamu akan belajar tentang bagaimana mengasuh dan mendidik anak hingga usia 5 tahun. Kamu juga akan belajar mengembangkan pendidikan yang merangsang pertumbuhan intelektual, fisik, sosial, dan emosional anak-anak.</p>
            <p></p>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="card shadow">
          <img src={Nursing} alt="HealthCare" className="custom-img" />
          <div className="card-body">
            <h5 className="card-title">
                <b>
                    <a href="https://stenbergcollege.com/program/health-care-assistant/" target='blank' className='custom-link'>Health Care Assistant Diploma</a>
                </b>
            </h5>
            <p>Di jurusan ini, kamu akan belajar lebih dalam terkait layanan kesehatan yang diberikan kepada lansia dan keluarga untuk meningkatkan kemandirian & menjaga kesehatan dan kualitas hidup mereka. 
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row m-5">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <h5><b>Benefit</b></h5>
                    <ol>
                        <li>Masuk dalam profesi essential sehingga gaji perjam jauh lebih tinggi dari UMR Kanada yaitu 20-24 CAD/jam</li>
                        <li>Stenberg College dilengkapi dengan program Co-op atau paid internship</li>
                        <li>Jurusan yang memiliki kesempatan besar untuk PR (Permanent Residents), bahkan masuk dalam Express Entry Visa (bisa daftar PR hanya 6 bulan setelah bekerja).</li>
                        <li>Jaminan pekerjaan 100% setelah lulus kuliah</li>
                    </ol>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <h5><b>Persyaratan Registrasi</b></h5>
                    <ul>
                        <li>IELTS 5.5 atau 6.0</li>
                        <li>Berusia 18-30 tahun</li>
                        <li>1 menit video yang menjelaskan mengapa kamu adalah kandidat yang cocok sebagai Student Ambassador Stenberg College </li>
                        <li>Ijazah dan Transkrip Nilai</li>
                        <li>CV</li>
                        <li>Passport</li>
                    </ul>
                </div>
            </div>
</section>


  )
}

export default WhySection