import React from 'react'
import '../style.css'
import Stenberg from '../../src/img/stenberg.jpg'
import Fade from 'react-reveal/Fade';

const AboutUs = () => {
  return (
    <section id='about-us'>
        <img src={Stenberg} alt="" />
        <div className="about-us">
            <h5 className='title'>Stenberg College Description</h5>
            <Fade>
                <p>Stenberg College merupakan institusi pendidikan tinggi yang terletak di Surrey, British Columbia, Canada yang berfokus pada jurusan kesehatan dan pendidikan usia dini, dengan harga terjangkau.</p>
            </Fade>
        </div>  
    </section>
  )
}

export default AboutUs