import AboutUs from './section/AboutUs';
import Consultation from './section/Consultation';
import Footer from './section/Footer';
import Header from './section/Header';
import Programs from './section/Program';
import Register from './section/Register';
import WhySection from './section/WhySection';
import Navbar from './section/navbar/Navbar';


function App() {
  return (
    <>
    <Navbar/>
    <Header/>
    <Register/>
    <WhySection/>
    <Programs/>
    <AboutUs/>
    <Consultation/>
    <Footer/>
    </>
  );
}

export default App;
