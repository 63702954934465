import React from 'react'
import '../style.css'
import Logo from '../../src/img/logo.png'

const Footer = () => {
    const year = new Date().getFullYear();
  return (
    <>
    <section id='footer'>
        <footer>
        <div className='container mt-2'>
                        <h3 style={{ fontWeight:"bolder", color:"black", marginBottom:'20px'}}>Our Branch</h3>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <p style={{ fontWeight:"bolder" , color:"black"}}>Denpasar Office</p>
                                <a 
                                    href='https://maps.app.goo.gl/udvbrV2H85Kbk5RC7' 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    style={{ color: "black", textDecoration: "none", fontSize:"13px" }} 
                                    onMouseEnter={(e) => e.target.style.color = "blue"} 
                                    onMouseLeave={(e) => e.target.style.color = "black"}>
                                    Jl. Buluh Indah No. 56, Denpasar Bali
                                </a>
                            </div>
                            <div className='col-lg-2'>
                                <p style={{ fontWeight:"bolder" , color:"black"}}>Yogyakarta Office</p>
                                <a 
                                    href='https://maps.app.goo.gl/RWGf5UPHyaz7CBm46' 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    style={{ color: "black", textDecoration: "none", fontSize:"13px"  }} 
                                    onMouseEnter={(e) => e.target.style.color = "blue"} 
                                    onMouseLeave={(e) => e.target.style.color = "black"}>
                                    Block C5. Ruko Yap Square C Simanjuntak, Terban, Gondokusuman, Yogyakarta
                                </a>
                            </div>
                            <div className='col-lg-2'>
                                <p style={{ fontWeight:"bolder" , color:"black"}}>Makassar Office</p>
                                <a 
                                    href='https://maps.app.goo.gl/2MehnGud3RLZVhEK7' 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    style={{ color: "black", textDecoration: "none", fontSize:"13px"  }} 
                                    onMouseEnter={(e) => e.target.style.color = "blue"} 
                                    onMouseLeave={(e) => e.target.style.color = "black"}>
                                    Bikin Bikin Creative Hub Mall Nipah, Jl. Urip Sumoharjo No.23C Panakkukang - Kota Makassar
                                </a>
                            </div>
                            <div className='col-lg-2'>
                                <p style={{ fontWeight:"bolder" , color:"black"}}>Medan Office</p>
                                <a 
                                    href='https://maps.app.goo.gl/7ssBVEWDyjicSujB6' 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    style={{ color: "black", textDecoration: "none", fontSize:"13px"  }} 
                                    onMouseEnter={(e) => e.target.style.color = "blue"} 
                                    onMouseLeave={(e) => e.target.style.color = "black"}>
                                    Cradle Coworking and Event Space, Jl. Iskandar Muda No.127 Medan Petisah - Medan
                                </a>
                            </div>
                            <div className='col-lg-2'>
                                <p style={{ fontWeight:"bolder" , color:"black"}}>Jakarta Office</p>
                                <a 
                                    href='https://maps.app.goo.gl/yEUFHazx599i3KNu6' 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    style={{ color: "black", textDecoration: "none", fontSize:"13px"  }} 
                                    onMouseEnter={(e) => e.target.style.color = "blue"} 
                                    onMouseLeave={(e) => e.target.style.color = "black"}>
                                    MULA Coworking (CITOS) JL TB Simatupang No17, Cilandak, Jakarta Selatan - DKIJakarta
                                </a>
                            </div>
                            <div className='col-lg-2'>
                                <p style={{ fontWeight:"bolder" , color:"black"}}>Surabaya Office</p>
                                <p style={{ color:"black", fontSize:"13px" }}>
                                    Urban Coworking JL Dr. Ir. H. Soekarno No470, Kedung Baruk, Rungkut - Surabaya
                                </p>
                            </div>
                        
                        </div>
                    </div>
        </footer>
        {/* <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="contact">
                        <h6>NIEC INDONESIA</h6>
                        <h6>Jl. Buluh Indah No 56 Denpasar</h6>
                        <h6><span>PHONE:</span> <a href="tel:+6289527440126">+62 895-2744-0126</a></h6>
                        <h6><span>EMAIL:</span> <a href="mailto:bizdev@niecindonesia.com">bizdev@niecindonesia.com</a></h6>
                        
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="social">
                        <h5>SOCIAL:</h5>
                        <a href="https://www.facebook.com/niec.indonesia" target="_blank"><i className="fa-brands fa-facebook"></i></a>
                        <a href="https://www.instagram.com/niec_indonesia" target="_blank"><i className="fa-brands fa-square-instagram"></i></a>
                        <a href="https://www.youtube.com/channel/UCA5qiOgJdfMvADpyU7Oz7fQ" target="_blank"><i className="fa-brands fa-youtube"></i></a>
                        <a href="https://twitter.com/NIEC_Indonesia" target="_blank"><i className="fa-brands fa-square-twitter"></i></a>
                    </div>
                </div>
            </div>
                <div className="logo">
                    <a href="https://www.icms.edu.au/" target="_blank" rel="noopener noreferrer">
                        <img src={Logo} alt="" />
                    </a>
                </div>
        </div> */}
    </section>
    <div className="footer">
        <div className="container text-center">
            &copy; {year} NIEC Indonesia. All Rights Reserved. CV Naresy Indonesia <br />
            Designed by NIEC IT Team
        </div>
    </div>
    </>
  )
}

export default Footer